import AspectRatio from "@mui/joy/AspectRatio";
import { Skeleton } from "@mui/material";
import { Link } from "react-router-dom";

type MediaSquareProps = {
  mediaId: string;
  image_src: string;
  loading: boolean;
};
const MediaSquareView = (props: MediaSquareProps) => {
  return (
    <>
      {props.loading ? (
        <AspectRatio
          objectFit="cover"
          ratio="1/1"
          sx={{
            m: 0.25,
          }}
        >
          <Skeleton variant="rectangular" height={1000} width={1000}></Skeleton>
        </AspectRatio>
      ) : (
        <AspectRatio
          objectFit="cover"
          ratio="1/1"
          sx={{
            m: 0.25,
          }}
        >
          <Link to={`/post/${props.mediaId}`}>
            <img src={props.image_src} alt="tileImage" />
          </Link>
        </AspectRatio>
      )}
    </>
  );
};

export default MediaSquareView;

import { useEffect } from "react";
import { useGetMedia } from "../../hooks";
import { FeedCardView } from "./FeedCardView";

type MediaCardProps = {
  mediaId: string;
  singlePage?: boolean;
};
const MediaCard = (props: MediaCardProps) => {
  const {
    media,
    loading,
    getMedia,
    error: mediaError,
  } = useGetMedia(props.mediaId);
  useEffect(() => {
    getMedia();
  }, [props.mediaId, getMedia]);
  return (
    <>
      {mediaError}
      {media && (
        <FeedCardView
          username={media.user_id}
          imageSrc={media.image_src}
          mediaId={props.mediaId}
          timestamp={media.timestamp}
          description={
            media.auto_annotated_labels
              ? media.auto_annotated_labels.map((item) => "#" + item).join(" ")
              : "aaa"
          }
          loading={loading}
          singlePage={props.singlePage}
        />
      )}
    </>
  );
};

export default MediaCard;

import { ChevronLeft } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/joy";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../molecules/Layout";
import MediaViewChanger from "../molecules/MediaViewChanger";
import UserMediaList from "../organisms/UserMediaList";

const UserPage = (props: {}) => {
  let { userId } = useParams();
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState<"tile" | "feed">("feed");

  return (
    <>
      <Layout>
        <Button
          startDecorator={<ChevronLeft />}
          variant="soft"
          color="neutral"
          onClick={() => navigate("/")}
          sx={{ mb: 1 }}
        >
          トップページ
        </Button>
        <Box mb={3} mt={1}>
          <Typography fontSize="xl">{userId} さんの投稿</Typography>
        </Box>
        <Box mb={1}>
          <MediaViewChanger setViewMode={setViewMode} />
        </Box>
        {userId && <UserMediaList view={viewMode} userId={userId} />}
      </Layout>
    </>
  );
};
export default UserPage;

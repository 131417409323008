import { Tab, TabList, Tabs } from "@mui/joy";
import { useState } from "react";

type MediaViewChangerProps = {
  setViewMode(viewMode: "tile" | "feed"): void;
};
const MediaViewChanger = ({ setViewMode }: MediaViewChangerProps) => {
  const [index, setIndex] = useState(0);
  return (
    <Tabs
      aria-label="Soft tabs"
      value={index}
      onChange={(event, value) => setIndex(value as number)}
      sx={{ borderRadius: "lg" }}
    >
      <TabList variant="soft">
        <Tab
          variant={index === 0 ? "solid" : "plain"}
          color={index === 0 ? "primary" : "neutral"}
          onChange={() => {
            setViewMode("feed");
          }}
        >
          フィード
        </Tab>
        <Tab
          variant={index === 1 ? "solid" : "plain"}
          color={index === 1 ? "primary" : "neutral"}
          onChange={() => {
            setViewMode("tile");
          }}
        >
          タイル
        </Tab>
      </TabList>
    </Tabs>
  );
};

export default MediaViewChanger;

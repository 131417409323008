import { useAuthenticator } from "@aws-amplify/ui-react";
import { Box, Stack, Typography } from "@mui/joy";
import { Link } from "react-router-dom";
import Logo from "../atoms/Logo";

import UserMenu from "./UserMenu";

const Layout = (props: { children?: React.ReactNode }) => {
  const { route } = useAuthenticator((context) => [context.route]);
  return (
    <>
      <Box
        borderBottom="1px solid"
        borderColor="neutral.100"
        position="fixed"
        top={0}
        width="100%"
        height={(theme) => theme.spacing(6)}
        px={1}
        sx={{ backdropFilter: "blur(10px)" }}
        zIndex={100}
      >
        <Box
          display="flex"
          alignItems="center"
          position="fixed"
          p={0.5}
          component={Link}
          to="/"
        >
          <Logo size={40} />
          <Typography sx={{ ml: 1 }}>名大歴史写真館</Typography>
        </Box>

        {route === "authenticated" ? (
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={0.5}
            p={0.5}
            px={2}
          >
            <UserMenu />
          </Stack>
        ) : null}
      </Box>
      <Box my={6} width="100%">
        <Box maxWidth={470} mx="auto" py={2} px={0.5}>
          {props.children}
        </Box>
      </Box>

      {/*<Box position="fixed" bottom="0px" width="100%" py={0.5} display="block">
        <Tabs
          aria-label="Icon tabs"
          defaultValue={0}
          variant="solid"
          sx={{
            maxWidth: 400,
            mx: "auto",
            px: 1,
            background: "none",
          }}
        >
          <TabList>
            <Tab>
              <Phone />
            </Tab>
            <Tab>
              <Favorite />
            </Tab>
            <Tab>
              <PersonPin />
            </Tab>
          </TabList>
        </Tabs>
      </Box>
      */}
    </>
  );
};

export default Layout;

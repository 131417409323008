import { Auth } from "aws-amplify";
import {
  MediaStoreApiClient,
  OpenAPIConfig,
} from "./generatedApi/mediaStoreApi";

const isLocal = process.env.REACT_APP_API_LOCAL === "true";

export const getMediaStoreApi = async () => {
  const token = (await Auth.currentSession()).getIdToken().getJwtToken();
  console.log(await Auth.currentSession())
  console.log(token)
  const params: Partial<OpenAPIConfig> = isLocal
    ? { BASE: `http://localhost:3201`, TOKEN: token }
    : {
        BASE: `${process.env.REACT_APP_API_HOST}/media_store`,
        TOKEN: token,
      };

  const mediaStoreApi = new MediaStoreApiClient(params);
  // NOTE: 以下の API が追加されている
  // mediaStoreApi.user.getPublicUser()
  // mediaStoreApi.user.getPrivateUser()
  return mediaStoreApi;
};

import { Auth } from "aws-amplify";
import { useCallback, useState } from "react";
import { getMediaStoreApi } from "./api";
import { MediaResponse } from "./generatedApi/mediaStoreApi";

export const useGetMedia = (mediaId: string) => {
  const [media, setMedia] = useState<MediaResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const getMedia = useCallback(async () => {
    setLoading(true);
    setError(null);
    const api = await getMediaStoreApi();
    try {
      const res = await api.media.getMediaById(mediaId);
      setMedia(res);
    } catch (error) {
      setError("画像の読み込みに失敗しました");
    } finally {
      setLoading(false);
    }
  }, [mediaId]);
  return { media, loading, getMedia, error };
};

export const useGetUserId = () => {
  const [userId, setUserId] = useState<string | "userId">("userId");
  const getUserId = useCallback(async () => {
    const token = (await Auth.currentSession()).getIdToken();
    // console.log(token.payload.sub);
    setUserId(token.payload.sub);
  }, []);
  return { userId, getUserId };
};

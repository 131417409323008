import { OpenInFull } from "@mui/icons-material";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import { Box, Link, Typography } from "@mui/joy";
import Avatar from "@mui/joy/Avatar";
import Card from "@mui/joy/Card";
import IconButton from "@mui/joy/IconButton";
import { SxProps } from "@mui/joy/styles/types";
import { Skeleton } from "@mui/material";
import { Stack } from "@mui/system";
import { Link as RouterLink } from "react-router-dom";
import TimeAgo from "react-timeago";

const FeedCardView = (props: {
  imageSrc?: string;
  mediaId?: string;
  username?: string;
  title?: string;
  description?: string;
  timestamp?: string;
  sx?: SxProps;
  loading?: boolean;
  singlePage?: boolean;
}) => {
  return (
    <Box mb={1} sx={props.sx}>
      <Card
        variant="outlined"
        sx={{
          p: 1,
          "--Card-radius": (theme) => theme.vars.radius.xs,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", pb: 1, gap: 1 }}>
          {props.loading ? (
            <Skeleton variant="circular">
              <Avatar />
            </Skeleton>
          ) : (
            <Avatar
              size="sm"
              component={RouterLink}
              to={`/user/${props.username}`}
            />
          )}
          {props.loading ? (
            <Skeleton width="100%">
              <Typography>.</Typography>
            </Skeleton>
          ) : (
            <Typography
              fontWeight="md"
              component={RouterLink}
              to={`/user/${props.username}`}
              sx={{ textDecoration: "none" }}
            >
              {props.username ?? "ゲストユーザ"}
            </Typography>
          )}

          {!props.singlePage && props.mediaId !== undefined && (
            <IconButton
              variant="plain"
              color="neutral"
              size="sm"
              component="a"
              href={"/post/" + props.mediaId}
              sx={{ ml: "auto" }}
            >
              <OpenInFull />
            </IconButton>
          )}
        </Box>
        <Box mx={-1} overflow="auto">
          {props.loading ? (
            <Skeleton variant="rectangular" width="100%">
              <div style={{ paddingTop: "73%" }} />
            </Skeleton>
          ) : (
            <img
              src={props.imageSrc}
              alt={props.title ?? "untitled"}
              width="100%"
            />
          )}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mx: -1, my: 0.5 }}>
          <Box sx={{ width: 0, display: "flex", gap: 0.5 }}>
            {props.loading ? (
              <Stack spacing={1} pt={2} pl={1}>
                <Skeleton variant="circular" width={20} height={20} />
              </Stack>
            ) : (
              <IconButton variant="plain" color="neutral" size="sm">
                <FavoriteBorder />
              </IconButton>
            )}
          </Box>
        </Box>
        {props.description &&
          (props.loading ? (
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          ) : (
            <Box m={1}>{props.description}</Box>
          ))}
        {props.timestamp &&
          (props.loading ? (
            <Skeleton width="15%" />
          ) : (
            <Link
              component="button"
              underline="none"
              fontSize="10px"
              sx={{ color: "text.tertiary", my: 0.25 }}
            >
              <TimeAgo date={props.timestamp} />
            </Link>
          ))}
      </Card>
    </Box>
  );
};

export { FeedCardView };

import { Authenticator, translations } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Box } from "@mui/joy";
import { I18n } from "aws-amplify";
import { useState } from "react";
import { getMediaStoreApi } from "../../api";
import Layout from "../molecules/Layout";
import MediaViewChanger from "../molecules/MediaViewChanger";
import MediaList from "../organisms/MediaList";
import PhotoUploader from "../organisms/PhotoUploader";

I18n.putVocabularies(translations);
I18n.setLanguage("ja");

const Home = () => {
  // const [im, setIm] = useState<string | null>(null);
  const test = async () => {
    const api = await getMediaStoreApi();
    const res = await api.example.getUserDetail();
    console.log(res);
  };
  const [viewMode, setViewMode] = useState<"tile" | "feed">("feed");
  return (
    <>
      <Layout>
        <Authenticator
          loginMechanisms={["email"]}
          signUpAttributes={["nickname"]}
          formFields={{
            signIn: {
              username: {
                label: "メールアドレス",
                placeholder: "メールアドレスを入力",
              },
              password: {
                label: "パスワード",
                placeholder: "パスワードを入力",
              },
            },
            signUp: {
              nickname: {
                label: "ニックネーム",
                placeholder: "ニックネームを入力",
                order: 2,
              },
              email: {
                label: "メールアドレス",
                placeholder: "メールアドレスを入力",
                order: 1,
              },
              password: {
                label: "パスワード",
                placeholder: "パスワードを入力",
                order: 3,
              },
              confirm_password: {
                label: "パスワードの確認",
                placeholder: "パスワードを再入力してください",
                order: 4,
              },
            },
          }}
        ></Authenticator>
        <Box mb={2}>
          <PhotoUploader />
        </Box>
        <Box mb={1}>
          <MediaViewChanger setViewMode={setViewMode} />
        </Box>
        <MediaList view={viewMode} sortBy="timestamp" sortAsc={false} />
        {/* {im && <img src={im} />} */}
      </Layout>
    </>
  );
};

export default Home;

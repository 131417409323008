import { useEffect } from "react";
import { useGetMedia } from "../../hooks";
import MediaSquare from "./MediaSquare";

type MediaSquareViewProps = {
  mediaId: string;
};
const MediaSquareView = (props: MediaSquareViewProps) => {
  const {
    media,
    loading,
    getMedia,
    error: mediaError,
  } = useGetMedia(props.mediaId);
  useEffect(() => {
    getMedia();
  }, [props.mediaId]);
  return (
    <>
      {mediaError}
      {media && (
        <MediaSquare
          image_src={media.image_src}
          loading={loading}
          mediaId={props.mediaId}
        />
      )}
    </>
  );
};

export default MediaSquareView;
